@import '../node_modules/papercss/dist/paper.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1400ms infinite;
  animation: ellipsis steps(4, end) 1400ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 44px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 44px;
  }
}

.border-glow {
  animation: pulse 2s infinite;
  animation-timing-function: linear;
}

.border-glow-highlight {
  animation: pulse-highlight 2s infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 2px 2px 15px #00ffff, -2px 2px 15px #00ffff, 2px -2px 15px #00ffff, -2px -2px 15px #00ffff;
  }
  50% {
    box-shadow: 2px 2px 15px #0000ff, -2px 2px 15px #0000ff, 2px -2px 15px #0000ff, -2px -2px 15px #0000ff;
  }
  100% {
    box-shadow: 2px 2px 15px #00ffff, -2px 2px 15px #00ffff, 2px -2px 15px #00ffff, -2px -2px 15px #00ffff;
  }
}

@keyframes pulse-highlight {
  0% {
    box-shadow: inset -5px -5px 5px white, inset 5px 5px 5px white;
  }
  50% {
    box-shadow: inset -25px -25px 25px white, inset 25px 25px 25px white;
  }
  100% {
    box-shadow: inset -5px -5px 5px white, inset 5px 5px 10px white;
  }
}
